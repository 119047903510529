import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Text } from "flicket-ui";
import isUrl from "is-url";
import { Editor, Range, Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from "slate-react";
import styled, { useTheme } from "styled-components";
import { Icon, Modal } from "~components";
import { Button } from "./components";
import { HistoryEditor } from "slate-history";
import { InsertModalContent } from "./InsertModal";
import { useOnClickOutside } from "~hooks";
import { StyledPopover } from "./InsertButton";
import { addProtocolToURL } from "~lib/helpers/addProtocolToURL";
import { createPortal } from "react-dom";
import { Link, PencilSimple, Trash } from "@phosphor-icons/react";
import { useRouter } from "next/router";
import { InsertSMSModalContent } from "~components/common/RichText/InsertSMSModal";
import { SuggestedLinkType } from "./InsertModal.types";

export const withLinks = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { insertData, insertText, isInline, isVoid } = editor;
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertText(text);
    }
  };

  editor.isVoid = (element) => {
    return element.type === "link" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, {
        url: text,
        at: blurSelection,
        content: text,
      });
    } else {
      insertData(data);
    }
  };

  return editor;
};

type InsertAndEditLinkOptions = {
  url?: string;
  content: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
  membershipId?: string;
};

export const insertLink = (
  editor: ReactEditor,
  options: InsertAndEditLinkOptions
) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const {
    content,
    suggestedLink,
    url,
    eventId,
    releaseId,
    membershipId,
  } = options;
  if (blurSelection) {
    wrapLink(editor, {
      url,
      at: blurSelection,
      content,
      suggestedLink,
      eventId,
      releaseId,
      membershipId,
    });
    // use the start of the link as the new selection
    // use the end of the link as offset will cause an error (not sure why)
    const minOffset = Math.min(
      blurSelection.anchor.offset,
      blurSelection.focus.offset
    );
    const newRange: Range = {
      anchor: { offset: minOffset, path: blurSelection.anchor.path },
      focus: { offset: minOffset, path: blurSelection.anchor.path },
    };
    editor.selection = newRange;
    ReactEditor.focus(editor);
  }
};

const editLink = (editor: ReactEditor, options: InsertAndEditLinkOptions) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }

  const {
    content,
    suggestedLink,
    url,
    eventId,
    releaseId,
    membershipId,
  } = options;

  let protocolURL = url;

  if (url) {
    protocolURL = addProtocolToURL(url);
  }

  Transforms.setNodes(
    editor,
    {
      url: protocolURL,
      suggestedLink,
      content,
      eventId,
      releaseId,
      membershipId,
    },
    {
      at: blurSelection,
      match: (n) => n.type === "link",
      mode: "lowest",
    }
  );

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

export const removeLink = (editor: ReactEditor) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }
};

type WrapLinkOptions = {
  url?: string;
  at: Range;
  content: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
  membershipId?: string;
};

export const wrapLink = (editor: ReactEditor, options: WrapLinkOptions) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const {
    at,
    content,
    suggestedLink,
    url,
    eventId,
    releaseId,
    membershipId,
  } = options;
  let protocolURL = url;

  const isCollapsed = Range.isCollapsed(at);

  if (url) {
    protocolURL = addProtocolToURL(url);
  }

  const link = {
    type: "link",
    url: protocolURL,
    content,
    suggestedLink,
    eventId,
    releaseId,
    membershipId,
    children: [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link, { at });
  } else {
    Transforms.wrapNodes(editor, link, { split: true, at });
    Transforms.collapse(editor, { edge: "end" });
  }
};

// this is used by the non-email editor
export const LinkButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState<string>(undefined);

  useEffect(() => {
    if (isOpen) {
      const selection = editor.selection;
      if (selection) {
        const isCollapsed = Range.isCollapsed(selection);
        if (!isCollapsed) {
          setSelectedText(Editor.string(editor, selection));
        }
      }
    } else {
      setSelectedText(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        w="30px"
        h="30px"
        active={isLinkActive(editor)}
        onClick={(event) => {
          event.preventDefault();
          setIsOpen(true);
        }}
      >
        <Icon icon={<Link />} />
      </Button>
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          defaultValues={{
            urlDisplayText: selectedText,
          }}
          setIsOpen={setIsOpen}
          onSuccess={({
            url,
            urlDisplayText,
            suggestedLink,
            event,
            release,
            membership,
          }) => {
            insertLink(editor, {
              url,
              content: urlDisplayText,
              suggestedLink,
              eventId: event?.id,
              releaseId: release?.id,
              membershipId: membership?.id,
            });
          }}
          isEmail={false}
        />
      </Modal>
    </>
  );
};

export const Popover = ({
  url,
  editor,
  setIsOpen,
  color,
}: {
  url?: string;
  editor: ReactEditor;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  color?: string;
}) => {
  const theme = useTheme();
  if (!color) {
    color = "#3869d4";
  }
  return (
    <>
      {url && (
        <Text
          as="a"
          variant="regular"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          contentEditable={false}
          css={`
            color: ${color};
            text-decoration: underline;
            margin-right: ${theme.space[1]}px;
            max-width: 200px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {url}
        </Text>
      )}

      <Button
        w="30px"
        h="30px"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon icon={<PencilSimple size={18} />} />
      </Button>

      <Button
        w="30px"
        h="30px"
        onClick={() => {
          editor.deleteBackward("block");
        }}
      >
        <Icon icon={<Trash size={18} />} />
      </Button>
    </>
  );
};

const StyledLink = styled.a<{ href: string; color: string }>`
  position: relative;
  color: ${(p) => p.color} !important;
  text-decoration: underline;
`;

const POPOVER_HEIGHT = 45;
const MAX_LINK_WIDTH = 200;

export const LinkItem: FC<
  Pick<RenderElementProps, "attributes"> & {
    url: string;
    content: string;
    style?: CSSProperties;
    isEmail: boolean;
    suggestedLink?: SuggestedLinkType;
    eventId?: string;
    releaseId?: string;
    membershipId?: string;
  }
> = ({
  attributes,
  url,
  children,
  style,
  content,
  isEmail,
  suggestedLink,
  eventId,
  releaseId,
  membershipId,
}) => {
  const selected = useSelected();
  const focused = useFocused();

  const router = useRouter();
  const editor = useSlate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsPopoverOpen(false));

  useEffect(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const buttonNodeArr = Array.from(
        Editor.nodes(editor, {
          match: (n) => n.type === "link",
        })
      );
      if (buttonNodeArr.length && selected && focused) {
        setIsPopoverOpen(true);
      }
    } else {
      setIsPopoverOpen(false);
    }
  }, [editor.selection]);

  const closestEditor = containerRef.current?.closest(".editor-wrapper");
  const popoverTop = containerRef.current?.offsetTop - POPOVER_HEIGHT;
  // 6 is the approximate width of the a character
  const urlWidth = Math.min(url.length * 6, MAX_LINK_WIDTH);

  const popoverLeft =
    containerRef.current?.offsetLeft +
    containerRef.current?.clientWidth / 2 -
    // 110 is the width of the popover except url
    (110 + urlWidth) / 2;

  return (
    <Box
      style={style}
      position={"relative"}
      display="inline-flex"
      justifyContent={"center"}
      as="span"
      ref={containerRef}
    >
      <StyledLink
        {...attributes}
        href={url}
        contentEditable={true}
        color={style.color}
      >
        {content}
        {children}
      </StyledLink>
      {isPopoverOpen &&
        createPortal(
          <StyledPopover
            ref={ref}
            top={`${popoverTop}px`}
            left={`${popoverLeft}px`}
          >
            <Popover url={url} editor={editor} setIsOpen={setIsOpen} />
          </StyledPopover>,
          closestEditor
        )}
      <Modal isOpen={isOpen} close={() => setIsOpen(false)}>
        {router.query.type === "sms" && (
          <InsertSMSModalContent
            setIsOpen={setIsOpen}
            isEdit
            defaultValues={{
              urlDisplayText: content,
              url,
              suggestedLink,
              event: { id: eventId, name: "" },
              release: { id: releaseId, name: "" },
              membership: { id: membershipId, name: "" },
              type: "link",
            }}
            onSuccess={({
              event,
              release,
              membership,
              urlDisplayText,
              suggestedLink,
              url,
            }) => {
              editLink(editor, {
                content: urlDisplayText,
                eventId: event?.id,
                releaseId: release?.id,
                membershipId: membership?.id,
                suggestedLink,
                url,
              });
            }}
            isEmail={isEmail}
          />
        )}
        {router.query.type !== "sms" && (
          <InsertModalContent
            setIsOpen={setIsOpen}
            isEdit
            defaultValues={{
              urlDisplayText: content,
              url,
              suggestedLink,
              event: { id: eventId, name: "" },
              release: { id: releaseId, name: "" },
              membership: { id: membershipId, name: "" },
            }}
            onSuccess={({
              event,
              release,
              membership,
              urlDisplayText,
              suggestedLink,
              url,
            }) => {
              editLink(editor, {
                content: urlDisplayText,
                eventId: event?.id,
                membershipId: membership?.id,
                releaseId: release?.id,
                suggestedLink,
                url,
              });
            }}
            isEmail={isEmail}
          />
        )}
      </Modal>
    </Box>
  );
};
