import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  ErrorMessage,
  Input,
  PrimaryButton,
  Stack,
  TertiaryButton,
  UnderlineButton,
} from "flicket-ui";
import { FormEvent, ReactNode } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import styled from "styled-components";
import Modal from "~components/common/CustomModal/CustomModal";
import {
  ImageUpload,
  ImageUploadEmptyState,
  ImgPreview,
} from "~features/generalAdmissionEvent/components/FormPartials/ImageUpload.form-partial";
import { FormValues, buildSchema } from "./schema";
import { ImageElement } from "../Image";
import { useFormWithSubmitState } from "~lib/helpers/form/useFormWithSubmitState";

const StyledImageUploadEmptyState = styled(ImageUploadEmptyState)<{
  error?: boolean;
}>`
  border: 1px solid ${(p) => p.theme.colors.error};
  ${(p) => !p.error && `border: none;`}
`;

export function ImageUploadField({
  name,
  description,
  ctaText = "Upload banner image",
  defaultValue = undefined,
  aspectRatio = 2 / 1,
  error,
}: {
  name: string;
  description: ReactNode;
  aspectRatio?: number;
  ctaText?: ReactNode;
  cropImage?: boolean;
  defaultValue?: unknown;
  error?: string;
}) {
  const ctx = useFormContext();
  const { control } = ctx;

  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={(props) => (
        <ImageUpload {...props}>
          {({ handleImageSelect, removeImage, hasImage, previewImageUrl }) => (
            <Stack direction={"vertical"}>
              {!hasImage && (
                <StyledImageUploadEmptyState
                  aspectRatio={aspectRatio}
                  handleImageSelect={handleImageSelect}
                  description={description}
                  ctaText={ctaText}
                  error={!!error}
                />
              )}

              {hasImage && (
                <Stack direction={"vertical"} gap={2}>
                  <ImgPreview src={previewImageUrl} title={"Event banner"} />
                  <UnderlineButton
                    onClick={removeImage}
                    fontSize={3}
                    color={"P300"}
                    fontWeight={"regular"}
                    textDecoration={"underline"}
                    alignSelf={"flex-end"}
                  >
                    Remove
                  </UnderlineButton>
                </Stack>
              )}

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Stack>
          )}
        </ImageUpload>
      )}
    />
  );
}

export interface InsertImageFormProps {
  close: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  afterSubmit?: () => void;
  image: ImageElement;
}

export default function InsertImageForm(props: InsertImageFormProps) {
  const { close, onSubmit, afterSubmit, image } = props;

  const { schema, formNames, defaultValues } = buildSchema(image);

  const _onSubmit = async (values: FormValues) => {
    try {
      await onSubmit(values);
      afterSubmit();
    } catch {
      return;
    }
  };

  const methods = useFormWithSubmitState({
    onSubmit: _onSubmit,
    onError: console.error,
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <Modal.Header>Insert image</Modal.Header>
        <Modal.Content>
          <Box
            id="insert-image"
            as="form"
            onSubmit={(e: FormEvent) => {
              e.stopPropagation();
              void methods.handleFormSubmit(e);
            }}
          >
            <Stack direction="vertical" gap={3}>
              <ImageUploadField
                name={"image"}
                ctaText="Upload image"
                description="Supported files JPEG, PNG or GIF"
                error={methods.errors?.image?.message}
              />
              <Controller
                as={Input}
                control={methods.control}
                name={formNames.imageLink}
                label="Image link (URL)"
                error={methods.errors?.imageLink?.message}
              />
            </Stack>
          </Box>
        </Modal.Content>
        <Modal.Footer>
          <Stack gap={1}>
            <TertiaryButton onClick={close} disabled={methods.isSubmitting}>
              Cancel
            </TertiaryButton>
            <PrimaryButton
              type="submit"
              form="insert-image"
              isLoading={methods.isSubmitting}
            >
              Insert
            </PrimaryButton>
          </Stack>
        </Modal.Footer>
      </FormProvider>
    </>
  );
}
