import {
  Box,
  Flex,
  Input,
  PrimaryButton,
  SecondaryButton,
  SegmentedControl,
  system,
  SystemProps,
  Text,
} from "flicket-ui";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { Divider, Select } from "~components";
import { useReleases } from "~graphql";
import { useSDK } from "~hooks";
import { showErrorToast } from "~lib";
import {
  InsertLinkModalContentState,
  modalOptions,
  ModalType,
  headerTextMap,
  InsertModalContentProps,
  SuggestedLinkType,
} from "./InsertModal.types";

const StyledLabel = styled.label<SystemProps>`
  padding-bottom: 8px;

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  letter-spacing: -0.165px;

  ${system}
`;

export const getHeaderText = ({
  suggestedLink,
  type,
  isEdit,
}: {
  suggestedLink?: SuggestedLinkType | undefined;
  type: ModalType;
  isEdit: boolean;
}) => {
  const text = suggestedLink ? headerTextMap[suggestedLink] : undefined;
  if (!suggestedLink) {
    let prefix = "Insert",
      suffix = "link";
    if (isEdit) {
      prefix = "Edit";
    }
    if (type === "button") {
      suffix = "button";
    }
    return `${prefix} ${suffix}`;
  }

  return isEdit ? `Edit ${text.toLowerCase()}` : text;
};

interface InsertActionButtonsProps {
  state: InsertLinkModalContentState;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: (state: InsertLinkModalContentState) => void;
  shouldLoadEvents: boolean;
  type: ModalType;
}

export const InsertActionButtons = ({
  setIsOpen,
  onSuccess,
  shouldLoadEvents,
  state,
  type,
}: InsertActionButtonsProps) => {
  const {
    url,
    suggestedLink,
    event,
    release,
    urlDisplayText,
    membership,
  } = state;
  return (
    <Flex justifyContent={"flex-end"} mt={3}>
      <SecondaryButton mr={3} onClick={() => setIsOpen(false)}>
        Cancel
      </SecondaryButton>
      <PrimaryButton
        onClick={() => {
          if (!state.urlDisplayText) {
            showErrorToast("Please fill in display text.");
          } else if (!url && !suggestedLink) {
            showErrorToast("Please fill in URL.");
          } else if (shouldLoadEvents && !event.id) {
            showErrorToast("Please select event.");
          } else {
            onSuccess({
              url,
              urlDisplayText,
              suggestedLink,
              type,
              event,
              release,
              membership,
            });
            setIsOpen(false);
          }
        }}
      >
        Apply
      </PrimaryButton>
    </Flex>
  );
};

export const InsertModalContent = ({
  setIsOpen,
  isEmail,
  isEdit,
  defaultValues,
  onSuccess,
}: InsertModalContentProps) => {
  const [modalState, setModalState] = useState<InsertLinkModalContentState>(
    defaultValues
  );
  const suggestedLink = defaultValues?.suggestedLink;
  const [type, setType] = useState<ModalType>(defaultValues?.type ?? "link");

  const sdk = useSDK();

  const headerText = getHeaderText({
    type,
    isEdit,
    suggestedLink,
  });

  const shouldLoadEvents =
    suggestedLink && (defaultValues?.selectEvents || defaultValues?.event?.id);

  const { data: paramsEvents } = shouldLoadEvents
    ? useSWR("paramsEvents", async () =>
        sdk
          .paramsEvents({
            where: {
              startDate: new Date().toISOString(),
            },
          })
          .then((res) => res?.events?.edges?.map(({ node }) => node))
      )
    : { data: null };

  const { data: releaseData } = useReleases(
    suggestedLink == "event-tickets" ? modalState?.event?.id : null
  );

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontWeight="heavy" fontSize={6}>
          {headerText}
        </Text>
      </Box>
      <Divider />
      {paramsEvents?.length ? (
        <Select
          options={paramsEvents
            ?.filter((event) => {
              if (suggestedLink === "event-registration") {
                return event.enableWaitlist;
              }
              return true;
            })
            .map((event) => ({
              label: event.title,
              value: event.id,
            }))}
          defaultValue={defaultValues?.event?.id}
          label={"Event"}
          onChange={(option) => {
            const eventName = paramsEvents?.find((event) => event.id === option)
              ?.title;
            setModalState({
              ...modalState,
              event: { name: eventName, id: option },
              urlDisplayText: eventName,
              url: "",
            });
          }}
          mt={4}
        />
      ) : null}
      {suggestedLink === "event-registration" && (
        <Text fontSize={2} color="N600" mt={1}>
          Only showing events with registration enabled.
        </Text>
      )}
      {releaseData?.length ? (
        <Select
          options={releaseData?.map((release) => ({
            label: release.name,
            value: release.id,
          }))}
          defaultValue={defaultValues?.release?.id}
          label={"Release"}
          onChange={(option) => {
            const releaseName = releaseData?.find(
              (release) => release.id === option
            )?.name;
            setModalState({
              ...modalState,
              release: { name: releaseName, id: option },
            });
          }}
          mt={4}
        />
      ) : null}
      {isEmail && !isEdit && (
        <Box mt={4}>
          <StyledLabel>Display as</StyledLabel>
          <SegmentedControl
            mt={1}
            value={type}
            onChange={(type) => setType(type as ModalType)}
            data={modalOptions.map(({ label, type }) => ({
              label,
              value: type,
            }))}
          />
        </Box>
      )}

      <Input
        label="Display text"
        autoFocus
        value={modalState?.urlDisplayText}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setModalState({ ...modalState, urlDisplayText: e.target.value })
        }
        mt={4}
      />

      {!suggestedLink && (
        <Input
          mt={3}
          label={"URL"}
          value={modalState?.url}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setModalState({ ...modalState, url: e.target.value })
          }
        />
      )}

      <InsertActionButtons
        setIsOpen={setIsOpen}
        onSuccess={onSuccess}
        type={type}
        shouldLoadEvents={false}
        state={modalState}
      />
    </>
  );
};
