import { Box } from "flicket-ui";
import {
  ChangeEvent,
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { components, GroupProps, IndicatorProps } from "react-select";
import { Editor, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor, useEditor } from "slate-react";
import styled, { css, DefaultTheme, useTheme } from "styled-components";
import { CustomFields } from "~features/broadcast/customFields.enum";
import { useSDK } from "~hooks";
import { exhaustiveGuard } from "~lib";
import { IconProps } from "../Icon/Icon";
import { ModalBase } from "../Modal";
import { Select } from "../Select";
import { insertDivider } from "./Divider";
import { fileSelectHandler } from "./Image";
import { imageModalAtom } from "./InsertImageModal/InsertImageModal";
import { insertButton } from "./InsertButton";
import { InsertModalContent } from "./InsertModal";
import { insertLink } from "./Link";
import { useScreenSize } from "~hooks/useScreenSize";
import { BroadcastAudience, BroadcastTransactionalType } from "~graphql/sdk";
import { insertSummary } from "./Summary";
import { useAtom } from "jotai";
import { videoModalState } from "./InsertVideoModal";

import {
  CaretDown,
  HandTap,
  Image,
  ImageSquare,
  Link,
  Minus,
  ShoppingCartSimple,
  TextT,
} from "@phosphor-icons/react";
import { InsertSelectCss } from "~components/common/RichText/styled/DropDown.styled";
import { SuggestedLinkType } from "./InsertModal.types";

const DropdownIndicator = (props: IndicatorProps<any, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown color="N800" size={16} />
    </components.DropdownIndicator>
  );
};

export type RichTextDropdownOption = {
  label: string;
  value: string;
  icon: IconProps["icon"];
};

export type SummaryType = "auto-renewal-details";

export type GroupInsertOptions = {
  options: RichTextDropdownOption[];
};

export const transactionalInsertOptions = (
  transactionalType: BroadcastTransactionalType = BroadcastTransactionalType.Event,
  audience: BroadcastAudience = null,
  hasResaleTicketType = false
): GroupInsertOptions[] => [
  {
    options: [
      { label: "Banner image", value: "banner", icon: <Image /> },
      { label: "Image", value: "image", icon: <ImageSquare /> },
      { label: "Divider", value: "divider", icon: <Minus /> },
      { label: "Link", value: "link", icon: <Link /> },
    ],
  },
  {
    options:
      transactionalType === BroadcastTransactionalType.Event
        ? ([
            {
              label: "Link to their tickets",
              value: "link:access-your-tickets",
              icon: <HandTap />,
            },
            {
              label: "Link to event page",
              value: "link:event-information",
              icon: <HandTap />,
            },
            hasResaleTicketType
              ? {
                  label: "Link to resale page",
                  value: "link:event-ticket-resale",
                  icon: <HandTap />,
                }
              : null,
          ]
            .filter(Boolean)
            .filter(
              (item) =>
                !(
                  audience ===
                    BroadcastAudience.RegistrationWaitlistCustomers &&
                  item.value == "link:access-your-tickets"
                )
            ) as [])
        : ([
            {
              label: "Link to membership renewal",
              value: "link:membership-renewal",
              icon: <HandTap />,
            },
            {
              label: "Auto renewal details",
              value: "summary:auto-renewal-details",
              icon: <ShoppingCartSimple />,
            },
          ].filter(
            (item) =>
              audience === BroadcastAudience.AutomaticMembershipRenewal ||
              item.value !== "summary:auto-renewal-details"
          ) as []),
  },
  {
    options: [
      {
        label: "First name",
        value: CustomFields.FIRST_NAME,
        icon: <TextT />,
      },
      {
        label: "Last name",
        value: CustomFields.LAST_NAME,
        icon: <TextT />,
      },
    ],
  },
];

export const smsInsertOptions: GroupInsertOptions[] = [
  {
    options: [
      { label: "Link", value: "link", icon: <Link /> },
      {
        label: "Link to ticketing page",
        value: "link:event-membership",
        icon: <HandTap />,
      },
    ],
  },
];

export const marketingInsertOptions: GroupInsertOptions[] = [
  {
    options: [
      { label: "Banner image", value: "banner", icon: <Image /> },
      { label: "Image", value: "image", icon: <ImageSquare /> },
      { label: "Divider", value: "divider", icon: <Minus /> },
      { label: "Link", value: "link", icon: <Link /> },
    ],
  },
  {
    options: [
      {
        label: "Link to event page",
        value: "link:event-information",
        icon: <HandTap />,
      },
      {
        label: "Link to ticketing page",
        value: "link:event-tickets",
        icon: <HandTap />,
      },
      {
        label: "Link to registration form",
        value: "link:event-registration",
        icon: <HandTap />,
      },
    ],
  },
  {
    options: [
      {
        label: "First name",
        value: CustomFields.FIRST_NAME,
        icon: <TextT />,
      },
      {
        label: "Last name",
        value: CustomFields.LAST_NAME,
        icon: <TextT />,
      },
    ],
  },
];

export const CustomFieldsOptions: RichTextDropdownOption[] = [
  { label: "First name", value: CustomFields.FIRST_NAME, icon: <TextT /> },
  { label: "Last name", value: CustomFields.LAST_NAME, icon: <TextT /> },
  { label: "Event name", value: CustomFields.EVENT_NAME, icon: <TextT /> },
];

export const withFields = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "field" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "field" ? true : isVoid(element);
  };

  return editor;
};

export const insertField = (editor: ReactEditor, content: string) => {
  let blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    blurSelection = {
      anchor: {
        offset: 0,
        path: [0, 0],
      },
      focus: {
        offset: 0,
        path: [0, 0],
      },
    };
  }
  const field = { type: "field", content, children: [{ text: "" }] };
  Transforms.insertNodes(editor, field, {
    at: blurSelection,
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export const StyledBox = styled(Box)<{ isFirst: boolean }>`
  position: relative;

  ${(props) =>
    !props.isFirst &&
    css`
      &::before {
        content: "";
        background: ${(props) => props.theme.colors.N200};
        position: absolute;
        left: 10%;
        top: 0;
        height: 1px;
        width: 80%;
      }
    `}
`;

export const defaultContent = (
  suggestedLink: SuggestedLinkType
): string | undefined => {
  if (!suggestedLink) return undefined;

  switch (suggestedLink) {
    case "access-your-tickets":
      return "Access your tickets";
    case "event-information":
      return "Event information";
    case "event-registration":
      return "Register now";
    case "event-tickets":
    case "event-ticket-resale":
      return "Event tickets";
    case "membership-renewal":
      return "Renew your membership";
    case "event-membership":
      return "Link to ticketing page";
    case "abandoned-cart-reengagement":
      return "Buy tickets";
    default:
      exhaustiveGuard(suggestedLink);
  }
};

export const InsertSelectStyles = (theme: DefaultTheme, isMobile: boolean) => {
  return {
    group: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    control: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      maxWidth: `${theme.space[15]}px`,
      height: `${theme.space[3]}px`,
      minHeight: `${theme.space[3]}px!important`,
      border: "none",
      alignContent: "center",
      boxShadow: "none!important",
    }),
    container: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      width: isMobile ? "100px" : "auto",
      alignSelf: "flex-start",
    }),
    menu: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      width: isMobile ? "200px" : "260px",
      marginTop: "12px",
      right: isMobile ? "-60px" : "-60px",
    }),
    singleValue: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "normal",
      color: "#1A1A1A",
    }),
    dropdownIndicator: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      paddingLeft: 4,
    }),
    menuList: (baseStyle: CSSProperties): CSSProperties => ({
      ...baseStyle,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: "normal",
      color: "#1A1A1A",
    }),
  };
};

export const RichtextEditorSelect: FC<{
  insertOptions?: GroupInsertOptions[];
  selectEvents?: boolean;
}> = ({ insertOptions = [], selectEvents = false }) => {
  const editor = useEditor();
  const sdk = useSDK();
  const theme = useTheme();
  const isMobile = useScreenSize().isPhonePortrait;
  const [isOpen, setIsOpen] = useState(false);
  const [suggestedLink, setSuggestedLink] = useState<SuggestedLinkType>(
    undefined
  );
  const [isBanner, setIsBanner] = useState(false);
  const [, setImageModalState] = useAtom(imageModalAtom);
  const [, setVideoModalState] = useAtom(videoModalState);
  const [, setSelectedText] = useState<string>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { Group } = components;

  const RichTextGroup = ({
    ...props
  }: GroupProps<RichTextDropdownOption, false>) => {
    // this is hard coded
    // props.options[0].value will use the value of the first option group
    // remove the divider for the first group
    let isFirst = false;
    if (props.options[0].value === insertOptions[0].options[0].value) {
      isFirst = true;
    }
    return (
      <StyledBox isFirst={isFirst}>
        <Group {...props} />
      </StyledBox>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const blurSelection = (editor.blurSelection as any) as Range;
      if (blurSelection) {
        const isCollapsed = Range.isCollapsed(blurSelection);
        if (!isCollapsed) {
          setSelectedText(Editor.string(editor, blurSelection));
        }
      }
    } else {
      setSelectedText(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Select
        width={"auto"}
        menuPortalTarget={document.body}
        options={insertOptions}
        onChange={(value: string) => {
          if (value === "divider") {
            insertDivider(editor);
          } else if (value === "image") {
            setIsBanner(false);
            setImageModalState({
              isOpen: true,
              image: null,
              isBanner: false,
            });
          } else if (value === "video") {
            setVideoModalState({ isOpen: true, video: null });
          } else if (value === "banner") {
            setIsBanner(true);
            setImageModalState({
              isOpen: true,
              image: null,
              isBanner: true,
            });
          } else if (value.startsWith("link")) {
            setIsOpen(true);
            setSuggestedLink(value.split(":")[1] as SuggestedLinkType);
          } else if (value.startsWith("summary")) {
            insertSummary(editor, value.split(":")[1] as SummaryType);
          } else {
            insertField(editor, value);
          }
        }}
        styles={InsertSelectStyles(theme, isMobile)}
        css={InsertSelectCss}
        isSearchable={false}
        value={{ label: "Insert", value: "" }}
        components={{ Group: RichTextGroup, DropdownIndicator }}
      />
      <ModalBase isOpen={isOpen} close={() => setIsOpen(false)}>
        <InsertModalContent
          setIsOpen={setIsOpen}
          isEmail
          defaultValues={{
            selectEvents,
            urlDisplayText: defaultContent(suggestedLink),
            suggestedLink,
          }}
          onSuccess={(options) => {
            const {
              type,
              urlDisplayText,
              suggestedLink,
              url,
              event,
              release,
              membership,
            } = options;
            if (type === "button") {
              insertButton(
                editor,
                urlDisplayText,
                url,
                suggestedLink,
                event?.id,
                release?.id,
                membership?.id
              );
            } else {
              insertLink(editor, {
                url,
                content: urlDisplayText,
                suggestedLink,
                eventId: event?.id,
                releaseId: release?.id,
                membershipId: membership?.id,
              });
            }
          }}
        />
      </ModalBase>

      <input
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        id="upload-image"
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const file =
            e.target.files && e.target.files.length > 0 && e.target.files[0];
          file &&
            fileSelectHandler(file, editor, sdk, isBanner).catch(console.error);
        }}
        ref={fileInputRef}
      />
    </>
  );
};

export const StyledCustomFieldElement = styled.span`
  box-sizing: border-box;
  background: ${(p) => p.theme.colors.N100};
  mix-blend-mode: multiply;
  border: 1px solid ${(p) => p.theme.colors.N300};
  border-radius: 4px;
  padding: 0 4px;
`;

export const CustomFieldElement = ({
  content,
  children,
}: {
  content: string;
  children: ReactNode;
}) => (
  <StyledCustomFieldElement contentEditable={false}>
    {children}
    {CustomFieldsOptions.find((option) => option.value === content)?.label}
  </StyledCustomFieldElement>
);
