import { Editor } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";

type ShouldPreventDeletionFn = (editor: ReactEditor) => boolean;

export type ShouldPreventDeletionValidators = {
  canDeleteBackward: ShouldPreventDeletionFn;
  canDeleteForward: ShouldPreventDeletionFn;
};

export const withPreventDeletion = (
  editor: Editor & ReactEditor & HistoryEditor,
  shouldPreventDeletionValidators?: ShouldPreventDeletionValidators
) => {
  const { deleteBackward, deleteForward } = editor;
  if (!shouldPreventDeletionValidators) {
    return editor;
  }
  const {
    canDeleteBackward,
    canDeleteForward,
  } = shouldPreventDeletionValidators;

  editor.deleteBackward = (unit) => {
    if (canDeleteBackward(editor)) {
      return;
    }
    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    if (canDeleteForward(editor)) {
      return;
    }

    deleteForward(unit);
  };

  return editor;
};
