export enum OrganizationFeatures {
  DigitalEvents = "digitalEvents",
  DigitalMemberships = "digitalMemberships",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  TempPOSOperators = "tempPOSOperators",
  Scout = "scout",
  Fiji = "fiji",
  Social = "social",
  XeroIntegration = "xeroIntegration",
  Account2account = "account2account",
  Forecast = "forecast",
  RenewalEmail = "renewalEmail",
  GroupLink = "groupLink",
  AutoRenewMemberships = "autoRenewMemberships",
  MembershipTransfers = "membershipTransfers",
  LegacyGASetup = "legacyGASetup",
  DisableEventListCards = "disableEventListCards",
  EventListHomepage = "eventListHomepage",
  PinPaymentGateway = "pinPaymentGateway",
  Competitions = "competitions",
  ZoneAlternativeEntryTime = "zoneAlternativeEntryTime",
  TicketResale = "ticketResale",
  GuestCard = "guestCard",
  Presale = "presale",
  LegacyPrivateLinks = "legacyPrivateLinksTab",
  MultiScanning = "multiScanning",
  BroadcastFilters = "broadcastFilters",
  OldNamingFields = "oldNamingFields",
  AskForDietaryRequirements = "askForDietaryRequirements",
  AskHowDidYouHearAboutUs = "askHowDidYouHearAboutUs",
  CustomTax = "customTax",
  SalesAndFinancialReportsV2 = "salesAndFinancialReportsV2",
  LegacyStripe = "canAddLegacyStripe",
  SMSMessageMedia = "smsMessageMedia",
  PointOfSale = "pointOfSale",
  DisableMJMLEmail = "disableMJMLEmail",
  LegacyGATicketSelection = "LegacyGATicketSelection",
  NetInsteadOfValueValue = "netInsteadOfValueValue",
  UsherComplimentary = "UsherComplimentary",
  PaymentPlans = "paymentPlans",
  ReportingIA = "reportingIA",
  ReleaseMembershipHolds = "releaseMembershipHolds",
  ScheduledReports = "scheduledReports",
  StripeOAuth = "stripeOAuth",
  BroadcastSMS = "broadcastSMS",
  CustomSystemEmails = "customSystemEmails",
  BandsInTown = "bandsInTown",
}
